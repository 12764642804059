<!--
 * @Descripttion: 组价清单筛选组件
 * @Author: liuxia
 * @Date: 2022-01-06 18:40:22
 * @LastEditors: renmingming
 * @LastEditTime: 2022-01-25 17:26:18
-->
<template>
  <div
    class="list-search"
    :class="{ enterpriseWidth: isQuotaLibraryIdentify === 1 }"
  >
    <div>
      <span>组价清单筛选</span>
      <a-checkbox-group
        :disabled="disabledCheckbox"
        :options="
          isQuotaLibraryIdentify === 1
            ? searchList_enterprise
            : searchList_personal
        "
        @change="change"
      />
      <a-icon type="close" @click="close" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'listSearch',
  data() {
    return {
      searchList_personal: [
        {
          label: '精准',
          value: 1,
        },
        {
          label: '近似',
          value: 2,
        },
        {
          label: '未组价',
          value: 3,
        },
        {
          label: '相同清单项',
          value: 4,
        },
      ],
      searchList_enterprise: [
        {
          label: '精准',
          value: 1,
        },
        {
          label: '近似',
          value: 2,
        },
        {
          label: '未组价',
          value: 3,
        },
        {
          label: '相同清单项',
          value: 4,
        },
        {
          label: '企业成本库',
          value: 5,
        },
        {
          label: '人工调整',
          value: 5001,
        },
      ],
      checkValue: '',
    };
  },
  computed: {
    ...mapGetters(['disabledCheckbox', 'isQuotaLibraryIdentify']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    change(checkedValue) {
      this.checkValue = checkedValue.toString();
      this.$store.commit('SET_CHECKEDVALUE', this.checkValue);
    },
  },
});
</script>

<style lang="less" scoped>
.list-search {
  width: 479px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: rgba(255, 255, 255, 0.39);
  border: 1px solid #eaecef;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  span {
    background: #1e90ff;
    font-size: 12px;
    display: inline-block;
    width: 92px;
    height: 38px;
    line-height: 38px;
    margin-right: 18px;
    border-radius: 6px 0 0 6px;
    color: #ffffff;
  }
}
.enterpriseWidth {
  width: 670px;
}
</style>
<style lang="less">
.list-search {
  .ant-checkbox-wrapper:nth-of-type(1) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #67c23a;
      border-color: #67c23a;
      &:hover {
        border-color: #67c23a;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #67c23a;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #67c23a;
    }
  }
  .ant-checkbox-wrapper:nth-of-type(2) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #ffaa09;
      border-color: #ffaa09;
      &:hover {
        border-color: #ffaa09;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #ffaa09;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #ffaa09;
    }
  }
  .ant-checkbox-wrapper:nth-of-type(3) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #ff5757;
      border-color: #ff5757;
      &:hover {
        border-color: #ff5757;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #ff5757;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #ff5757;
    }
  }
  .ant-checkbox-wrapper:nth-of-type(4) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #1e90ff;
      border-color: #1e90ff;
      &:hover {
        border-color: #1e90ff;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #1e90ff;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #1e90ff;
    }
  }
  .ant-checkbox-wrapper:nth-of-type(5) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #1e90ff;
      border-color: #1e90ff;
      &:hover {
        border-color: #1e90ff;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #1e90ff;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #1e90ff;
    }
  }
  .ant-checkbox-wrapper:nth-of-type(6) {
    .ant-checkbox-inner {
      background-color: #ffffff;
      color: #1e90ff;
      border-color: #1e90ff;
      &:hover {
        border-color: #1e90ff;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #1e90ff;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #1e90ff;
    }
  }
}
</style>
