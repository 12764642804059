








































































import Vue from 'vue';
import mixin from './mixin';
import { accAdd } from '@/utils';

const columnConfig = [
  {
    dataIndex: 'dispNo',
    width: 70,
    key: 'dispNo',
    align: 'center',
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    scopedSlots: { customRender: 'fxCode' },
    key: 'fxCode',
    align: 'center',
    width: 150,
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          attrs: {
            colSpan: 9,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '项目名称',
    dataIndex: 'fxName',
    key: 'fxName',
    align: 'center',
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '金额(元)',
    dataIndex: 'total',
    key: 'total',
    align: 'center',
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '计算基数',
    dataIndex: 'formulaRemark',
    key: 'formulaRemark',
    align: 'center',
    scopedSlots: { customRender: 'formulaRemark' },
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '费率(%)',
    dataIndex: 'fixRate',
    key: 'fixRate',
    align: 'center',
    scopedSlots: { customRender: 'fixRate' },
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '人工费(元)',
    dataIndex: 'totalRgf',
    key: 'totalRgf',
    align: 'center',
    scopedSlots: { customRender: 'totalRgf' },
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '材料费(元)',
    dataIndex: 'totalClf',
    key: 'totalClf',
    align: 'center',
    scopedSlots: { customRender: 'totalClf' },
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '机械费(元)',
    dataIndex: 'totalJxf',
    key: 'totalJxf',
    align: 'center',
    scopedSlots: { customRender: 'totalJxf' },
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
  {
    title: '管理费及利润(元)',
    dataIndex: 'totalGlf',
    key: 'totalGlf',
    align: 'center',
    scopedSlots: { customRender: 'totalGlf' },
    width: 150,
    customCell: (record) => {
      if (!record.fxCode) {
        return {
          style: { display: 'none' },
          attrs: {
            colSpan: 0,
          },
        };
      } else if (record.fxCode) {
        return {
          attrs: {
            colSpan: 1,
          },
        };
      }
    },
  },
];

export default Vue.extend({
  name: 'priceMeasuresItem',
  mixins: [mixin],
  data() {
    return {
      columnConfig,
    };
  },
  methods: {
    accAdd,
  },
});
