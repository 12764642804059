
































































import Vue from 'vue';
import tabs from '../tabs.vue';
import subItemEngineering from '../tender/subItemEngineering.vue';
import priceMeasuresItem from '../tender/priceMeasuresItem.vue';
import totalMeasuresItem from '../tender/totalMeasuresItem.vue';
import otherItem from '../tender/otherItem.vue';
import provisionalSumItem from '../tender/provisionalSumItem.vue';
import provisionalEstimateItem from '../tender/provisionalEstimateItem.vue';
import generalContractingItem from '../tender/generalContractingItem.vue';
import datallerItem from '../tender/datallerItem.vue';
import mainMaterial from '../tender/mainMaterial.vue';
import supplyMaterial from '../tender/supplyMaterial.vue';
import feesItem from '../tender/feesItem.vue';
import safeProductionAndCivilizedConstruction from '../tender/safeProductionAndCivilizedConstruction.vue';
import taxAction from '../tender/taxAction.vue';
import inputVat from '../tender/inputVAT.vue';
import equipmentVat from '../tender/equipmentVAT.vue';
import * as api from '@/api/projectDetail';
import { mapGetters } from 'vuex';
import NoCombinedPriceInfo from '@/views/projects/detail/lackdata/no-combined-price-info.vue';
import ListSearch from '@/views/projects/detail/combinedPrice/listSearch.vue';
import { setTableHeaderWidth } from '@/utils';

interface resData {
  [key: string]: any;
}
interface tabsData {
  description: string;
  extend1: string;
  extend2: string;
  extend3: string;
  fbName: string;
  fromType: string;
  itemCategory: string;
  recDate: string;
  recStatus: string;
  recUserId: string;
  sequenceNbr: string;
  xmLever: string;
  xmType: string;
  name: string;
}

export default Vue.extend({
  components: {
    ListSearch,
    NoCombinedPriceInfo,
    tabs,
    subItemEngineering,
    priceMeasuresItem,
    totalMeasuresItem,
    feesItem,
    otherItem,
    provisionalSumItem,
    provisionalEstimateItem,
    generalContractingItem,
    datallerItem,
    mainMaterial,
    supplyMaterial,
    safeProductionAndCivilizedConstruction,
    taxAction,
    inputVat,
    equipmentVat,
  },
  name: 'tender',
  data() {
    return {
      tabs: [],
      componentName: 'subItemEngineering',
      componentHeight: 0,
      tableHeadHeight: 0,
      compoundMode: {
        '0101': 'subItemEngineering',
        '0204': 'priceMeasuresItem',
        '0205': 'totalMeasuresItem',
        '0301': 'otherItem',
        '0302': 'provisionalSumItem',
        '0303': 'provisionalEstimateItem',
        '0304': 'generalContractingItem',
        '0305': 'datallerItem',
        '04': 'feesItem',
        '05': 'safeProductionAndCivilizedConstruction',
        '0602': 'taxAction',
        '0603': 'inputVat',
        '0604': 'equipmentVat',
        '0701': 'mainMaterial',
        '0702': 'supplyMaterial',
      } as Record<string, string>,
      xmType: '',
      itemCategory: '',
      fbSequenceNbr: '',
      adjustmentLoading: false,
      sequenceNbr: '',
      searchShow: false,
      tabsList: [
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '分部分项综合单价分析表',
          xmType: '01',
          itemCategory: '01',
          xmLever: null,
          fromType: null,
          name: 'subItemEngineering',
          label: '分部分项综合单价分析表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '单价措施综合单价分析表',
          xmType: '02',
          itemCategory: '02',
          xmLever: null,
          fromType: null,
          name: 'priceMeasuresItem',
          label: '单价措施综合单价分析表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '总价措施综合单价分析表',
          xmType: '02',
          itemCategory: '03',
          xmLever: null,
          fromType: null,
          name: 'totalMeasuresItem',
          label: '总价措施综合单价分析表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '其他项目清单表',
          xmType: '03',
          itemCategory: '01',
          xmLever: null,
          fromType: null,
          name: 'otherItem',
          label: '其他项目清单表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '暂列金额明细表',
          xmType: '03',
          itemCategory: '02',
          xmLever: null,
          fromType: null,
          name: 'provisionalSumItem',
          label: '暂列金额明细表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '暂估价表',
          xmType: '03',
          itemCategory: '03',
          xmLever: null,
          fromType: null,
          name: 'provisionalEstimateItem',
          label: '暂估价表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '总承包费用表',
          xmType: '03',
          itemCategory: '04',
          xmLever: null,
          fromType: null,
          name: 'generalContractingItem',
          label: '总承包费用表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '计日工',
          xmType: '03',
          itemCategory: '05',
          xmLever: null,
          fromType: null,
          name: 'datallerItem',
          label: '计日工',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '安全文明施工费明细表',
          xmType: '05',
          itemCategory: null,
          xmLever: null,
          fromType: null,
          name: 'safeProductionAndCivilizedConstruction',
          label: '安全文明施工费明细表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '规费明细表',
          xmType: '04',
          itemCategory: null,
          xmLever: null,
          fromType: null,
          name: 'feesItem',
          label: '规费明细表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '增值税进项税额计算汇总表',
          xmType: '06',
          itemCategory: '03',
          xmLever: null,
          fromType: null,
          name: 'inputVat',
          label: '增值税进项税额计算汇总表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '主要材料/设备表',
          xmType: '07',
          itemCategory: '01',
          xmLever: null,
          fromType: null,
          name: 'mainMaterial',
          label: '主要材料/设备表',
        },
        {
          sequenceNbr: null,
          recUserId: null,
          recDate: null,
          recStatus: null,
          extend1: null,
          extend2: null,
          extend3: null,
          description: null,
          fbName: '招标人供应材料/设备表',
          xmType: '07',
          itemCategory: '02',
          xmLever: null,
          fromType: null,
          name: 'supplyMaterial',
          label: '招标人供应材料/设备表',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'bidSequenceNbr',
      'isUnfold',
      'isTenderUnfold',
      'projectBidSequenceNbr',
      'combinedPriceStatus',
      'currentTreeInfo',
      'relevancy',
      'isThereReplace',
      'tenderRelevancy',
      'projectSequenceNbr',
      'selectStatus',
      'readOnly',
    ]),
    componentsStyle(): { height: string } {
      let height = 0;
      if (this.tenderRelevancy) {
        height = window.innerHeight - this.tableHeadHeight - 56 - 56 - 32; // 空数据的样式高度 = 50vh - 表头高度 - margin(上下28) - padding(上下16)
      } else {
        height = this.componentHeight - this.tableHeadHeight - 32; // 空数据的样式高度 = 表格总高度 - 表头高度 - padding(16+16)
      }
      return {
        height: height + 'px',
      };
    },
  },
  watch: {
    bidSequenceNbr: function (val, oldVal) {
      if (val === oldVal) return '';
      this.componentName = 'subItemEngineering';
      !this.readOnly && this.getCategoryByUnitBidSequenceNbr();
    },
    isTenderUnfold: function (val) {
      this.$store.commit('SET_ISTENDERUNFOLD', val);
      this.setComponentHeight();
    },
    isUnfold: function (val) {
      this.setComponentHeight();
      if (!val) {
        this.close();
      }
    },
    searchShow: function () {
      this.setComponentHeight();
    },
  },
  created() {
    this.sequenceNbr =
      (this.$route.query.sequenceNbr as string) || this.projectSequenceNbr;
    !this.readOnly && this.getCategoryByUnitBidSequenceNbr();
    if (this.selectStatus) {
      this.searchShow = true;
      this.$store.commit('SET_CHECKEDVALUE', '1,2,3,4');
    }
    if (this.readOnly) {
      (this.tabs as resData) = this.tabsList;
    }
  },
  mounted() {
    this.setComponentHeight();
    window.addEventListener('resize', this.getTableHeaderWidth);
  },
  methods: {
    getTableHeaderWidth() {
      setTableHeaderWidth('.tenderJs');
    },
    updateTab(tab: string) {
      this.componentName = tab;
      let obj = this.tabs.filter((item: tabsData) => item.name === tab)[0];
      this.xmType = (obj as tabsData).xmType;
      this.itemCategory = (obj as tabsData).itemCategory;
      this.fbSequenceNbr = (obj as tabsData).sequenceNbr;
      this.$store.commit('SET_RELEVANCY', '');
      this.$store.commit('SET_TENDERRELEVANCY', '');
      if (
        this.projectBidSequenceNbr &&
        this.componentName !== 'subItemEngineering' &&
        this.componentName !== 'priceMeasuresItem'
      ) {
        this.close();
      }
    },
    openSearch() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
      }
      if (
        this.projectBidSequenceNbr &&
        (this.componentName === 'subItemEngineering' ||
          this.componentName === 'priceMeasuresItem')
      ) {
        if (this.isTenderUnfold) {
          this.$store.commit('SET_ISTENDERUNFOLD', !this.isTenderUnfold);
        }
        if (!this.isTenderUnfold) {
          this.$store.commit('SET_ISUNFOLD', true);
        }
        this.searchShow = !this.searchShow;
        if (this.searchShow) {
          this.$store.commit('SET_CHECKEDVALUE', '1,2,3,4');
        } else {
          this.$store.commit('SET_CHECKEDVALUE', '');
        }
      }
    },
    close() {
      this.$store.commit('SET_CHECKEDVALUE', '');
      if (this.selectStatus) {
        this.$store.commit('SET_SELECTSTATUS', false);
      }
      this.searchShow = false;
    },
    setComponentHeight() {
      this.$nextTick(() => {
        const projectEl = this.$refs.tender as HTMLDivElement;
        const totalHeight = projectEl.clientHeight;
        const tenderHeaderEl = this.$refs.tenderHeader as HTMLDivElement;
        const tenderHeaderHeight = tenderHeaderEl.clientHeight;
        const searchHeight = this.searchShow ? 38 : 0;
        this.componentHeight =
          totalHeight - tenderHeaderHeight - 3 - searchHeight; // 总投标的高度-头部标题高度 - paddingTop
        const tableEl = document.querySelector(
          '.ant-table-thead'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    /**
     * 获取投标tab菜单
     */
    getCategoryByUnitBidSequenceNbr() {
      let apiData = {
        unitSequenceNbr: this.bidSequenceNbr,
        biddingType: 1, // 0招标 1 投标
      };
      api
        .coccMtdsResource_1_project_unitsCategory(apiData)
        .then((res: resData) => {
          if (res.code) {
            for (let i in this.compoundMode) {
              res.result.map((item: any) => {
                if (
                  item.xmType + (item.itemCategory ? item.itemCategory : '') ===
                  i
                ) {
                  item.name = this.compoundMode[i];
                  item.label = item.fbName;
                }
              });
            }
            let obj = {
              description: null,
              extend1: null,
              extend2: null,
              extend3: null,
              fbName: '材料、机械、设备增值税计算表',
              fromType: null,
              itemCategory: '04',
              recDate: null,
              recStatus: null,
              recUserId: null,
              sequenceNbr: '147852369874556321',
              xmLever: null,
              xmType: '06',
              label: '材料、机械、设备增值税计算表',
              name: 'equipmentVat',
            };
            res.result.push(obj);
            console.log('res.result', res.result);
            this.xmType = res.result[0].xmType;
            this.itemCategory = res.result[0].itemCategory;
            this.fbSequenceNbr = res.result[0].sequenceNbr as string;
            this.tabs = res.result;
            const receiveResult = JSON.parse(
              sessionStorage.getItem('receiveResult') as string
            );
            if (receiveResult && receiveResult.tbQdSequenceNbr) {
              this.componentName =
                receiveResult.queryTable === 'djcs'
                  ? res.result[1].name
                  : res.result[0].name;
              this.xmType =
                receiveResult.queryTable === 'djcs'
                  ? res.result[1].xmType
                  : res.result[0].xmType;
              this.itemCategory =
                receiveResult.queryTable === 'djcs'
                  ? res.result[1].itemCategory
                  : res.result[0].itemCategory;
              this.fbSequenceNbr =
                receiveResult.queryTable === 'djcs'
                  ? (res.result[1].sequenceNbr as string)
                  : (res.result[0].sequenceNbr as string);
            } else {
              this.componentName = res.result[0].name;
            }
            this.setComponentHeight();
          }
        });
    },
    /**
     * 展开收缩方法
     */
    handleOptions() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      this.$store.commit('SET_ISTENDERUNFOLD', !this.isTenderUnfold);
      if (this.isTenderUnfold) {
        this.$store.commit('SET_ISUNFOLD', true);
        this.$store.commit('SET_ISRETRACTUNFOLD', true);
      } else {
        this.$store.commit('SET_ISRETRACTUNFOLD', false);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getTableHeaderWidth, false);
  },
  destroyed() {
    this.$store.commit('SET_CHECKEDVALUE', '');
  },
});
