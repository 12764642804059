import { render, staticRenderFns } from "./taxAction.vue?vue&type=template&id=27ce0eb1&scoped=true&"
import script from "./taxAction.vue?vue&type=script&lang=ts&"
export * from "./taxAction.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ce0eb1",
  null
  
)

export default component.exports