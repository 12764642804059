







































































































import Vue from 'vue';
import mixin from './mixin';
import RecommendPrice from '@/views/projects/detail/recommendPrice/index.vue';
import QuotaDetail from '@/views/projects/detail/quotaDetail/index.vue';
import { mapGetters } from 'vuex';
import { accAdd } from '@/utils';

interface totalItem {
  createDate: number;
  creator: string;
  delFlag: string;
  deptId: string;
  dispNo: string;
  fbId: string;
  fxCode: string;
  fxName: string;
  id: string;
  istb: number;
  itemCategory: string;
  kind: string;
  parentId: string;
  price: string;
  projectAttr: string;
  quantity: string;
  quantityStr: string;
  rowNum: string;
  sortNo: number;
  standardName: string;
  status: string;
  tenantId: number;
  total: string;
  totalClf: string;
  totalGlf: string;
  totalJxf: string;
  totalRgdj: string;
  totalRgf: string;
  unit: string;
  updateDate: number;
  updater: string;
  xmLever: string;
  zhygfl: string;
  visible: boolean;
  sequenceNbr: string;
}

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    scopedSlots: { customRender: 'fxCode' },
    key: 'fxCode',
    align: 'center',
    width: 200,
    customCell: () => {
      return { style: { position: 'relative' } };
    },
  },
  {
    title: '项目名称',
    dataIndex: 'fxName',
    key: 'fxName',
    align: 'center',
    width: 200,
  },
  {
    title: '项目特征',
    dataIndex: 'projectAttr',
    scopedSlots: { customRender: 'projectAttr' },
    key: 'projectAttr',
    align: 'left',
    width: 220,
  },
  {
    title: '计量单位',
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    width: 100,
  },
  {
    title: '工程数量',
    dataIndex: 'quantityStr',
    key: 'quantityStr',
    align: 'center',
    width: 150,
  },
  {
    title: '综合单价(元)',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
    width: 150,
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
    },
  },
  {
    title: '合计(元)',
    dataIndex: 'total',
    key: 'total',
    align: 'center',
    width: 150,
    scopedSlots: { customRender: 'total' },
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
      if (record.kind === '03') {
        return { style: { background: '#ffffff' } };
      }
    },
  },
  {
    title: '人工费(元)',
    dataIndex: 'totalRgf',
    key: 'totalRgf',
    align: 'center',
    width: 100,
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
      if (record.kind === '03') {
        return { style: { background: '#ffffff' } };
      }
    },
  },
  {
    title: '材料费(元)',
    dataIndex: 'totalClf',
    key: 'totalClf',
    align: 'center',
    width: 100,
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
      if (record.kind === '03') {
        return { style: { background: '#ffffff' } };
      }
    },
  },
  {
    title: '机械费(元)',
    dataIndex: 'totalJxf',
    key: 'totalJxf',
    align: 'center',
    width: 100,
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
      if (record.kind === '03') {
        return { style: { background: '#ffffff' } };
      }
    },
  },
  {
    title: '管理费及利润(元)',
    dataIndex: 'totalGlf',
    key: 'totalGlf',
    align: 'center',
    width: 150,
    scopedSlots: { customRender: 'totalGlf' },
    customCell: (record: totalItem) => {
      if (record.sequenceNbr.toString().length < 5) {
        return { style: { filter: 'blur(2px)' } };
      }
      if (record.kind === '03') {
        return { style: { background: '#ffffff' } };
      }
    },
  },
];

export default Vue.extend({
  name: 'subItemEngineering',
  mixins: [mixin],
  data() {
    return {
      columns,
      record: null,
      recommendVisible: false,
      quotaVisible: false,
      currentQuotaInfo: null,
    };
  },
  computed: {
    ...mapGetters([
      'relevancy',
      'isPriceAdjustmentOrValuation',
      'combinedPriceStatus',
      'listGenerating',
      'isPriceAdjustment',
      'readOnly',
    ]),
  },
  components: {
    RecommendPrice,
    QuotaDetail,
  },
  watch: {
    relevancy: function (val) {
      if (val) {
        let bodyEl;
        let scrollTop = 0;
        if ((this as any).tableData) {
          (this as any).tableData.forEach((item, index) => {
            if (item.fxCode === val) {
              (this as any).invitationSequenceNbr = item.sequenceNbr;
              bodyEl = (this.$refs.table as any).$children[0].$children[0]
                .$children[0].$children[0].$children[0].$children[0]
                .$children[1];
              scrollTop =
                bodyEl.$children[0].$children[index + 1].$el.offsetTop;
            }
          });
        }
        (
          document.querySelector(
            '.tender-item-engineering .ant-table-body'
          ) as HTMLDivElement
        ).scrollTop = scrollTop;
      }
    },
  },
  methods: {
    accAdd,
    goLogin() {
      this.$store.commit('SET_LOGINVISIBLE', true);
    },
    // 项目编码点击事件
    handleCode(record: any, event) {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      if (!this.isNextOperation()) return false;
      (this as any).currentOffsetTop =
        event.currentTarget.parentElement.parentElement.offsetTop;
      (this as any).replaceFinish = false;
      this.recommendVisible = true;
      this.record = record;
      if (record.biddingType === 0) {
        this.$store.commit('SET_RELEVANCY', record.fxCode);
        this.$store.commit('SET_TENDERRELEVANCY', '');
      } else {
        this.$store.commit('SET_RELEVANCY', '');
        this.$store.commit('SET_TENDERRELEVANCY', record.sequenceNbr);
      }
    },
    handleQuotaCode(record) {
      if (!this.isNextOperation()) return false;
      this.record = (this as any).tableData.filter(
        (x) => x.sequenceNbr === record.parentId
      )[0];
      this.currentQuotaInfo = record;
      this.quotaVisible = true;
    },
    isNextOperation() {
      if (this.combinedPriceStatus) {
        this.$message.warning('项目正在组价中');
        return false;
      }
      if (this.isPriceAdjustmentOrValuation) {
        this.$message.warning('项目工程量清单重新计价中');
        return false;
      }
      if (this.isPriceAdjustment) {
        this.$message.warning('项目工程量清单存在人工调价未完成项');
        return false;
      }
      return true;
    },
  },
});
