<!--
 * @Descripttion: 规费
 * @Author: liuxia
 * @Date: 2021-10-15 09:38:25
 * @LastEditors: sunchen
 * @LastEditTime: 2023-01-07 09:21:35
-->
<template>
  <div class="tender-item-engineering">
    <a-table
      ref="table"
      rowKey="sequenceNbr"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :scroll="tableScroll"
      :loading="loading"
      :style="componentsStyle"
      bordered
    >
      <span slot="customTitle">
        <span class="icon-custom"></span>
      </span>
      <span slot="custombase">
        <span>人工预算价+机械预算价</span>
      </span>
    </a-table>
  </div>
</template>
<script>
import Vue from 'vue';
import mixin from './mixin';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '取费专业名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '取费基数',
    dataIndex: 'custombase',
    key: 'custombase',
    align: 'center',
    scopedSlots: { customRender: 'custombase' },
  },
  {
    title: '取费金额',
    dataIndex: 'baseamount',
    key: 'baseamount',
    align: 'center',
  },
  { title: '费率(%)', dataIndex: 'gfeerate', key: 'gfeerate', align: 'center' },
  {
    title: '规费金额',
    dataIndex: 'totalamount',
    key: 'totalamount',
    align: 'center',
  },
];

export default Vue.extend({
  data() {
    return {
      columns,
    };
  },
  mixins: [mixin],
});
</script>
