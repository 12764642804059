



























import Vue from 'vue';
import mixin from './mixin';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  { title: '项目名称', dataIndex: 'fxName', key: 'fxName', align: 'center' },
  { title: '项目金额(元)', dataIndex: 'xmje', key: 'xmje', align: 'center' },
  { title: '费率(%)', dataIndex: 'rate', key: 'rate', align: 'center' },
  { title: '金额(元)', dataIndex: 'fwje', key: 'fwje', align: 'center' },
];

export default Vue.extend({
  name: 'generalContractingItem',
  mixins: [mixin],
  data() {
    return {
      columns,
    };
  },
});
