



























import Vue from 'vue';
import mixin from './mixin';
import { mapGetters } from 'vuex';
import { getCjszzs } from '@/api/projectDetail';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    width: 70,
    align: 'center',
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  { title: '编码', dataIndex: 'rcjCode', key: 'rcjCode', align: 'center' },
  {
    title: '名称及型号规格',
    dataIndex: 'nameCode',
    key: 'nameCode',
    align: 'center',
  },
  { title: '单位', dataIndex: 'unit', key: 'unit', align: 'center' },
  {
    title: '数量',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: '除税系数(%)',
    dataIndex: 'taxRemoval',
    key: 'taxRemoval',
    align: 'center',
  },
  {
    title: '含税价格(元)',
    dataIndex: 'standardPrice',
    key: 'standardPrice',
    align: 'center',
  },
  {
    title: '含税价格合计(元)',
    dataIndex: 'standardTotal',
    key: 'standardTotal',
    align: 'center',
  },
  {
    title: '除税价格(元)',
    dataIndex: 'excludingTaxPrices',
    key: 'excludingTaxPrices',
    align: 'center',
  },
  {
    title: '除税价格合计(元)',
    dataIndex: 'excludingTaxTotal',
    key: 'excludingTaxTotal',
    align: 'center',
  },
  {
    title: '进项税额合计(元)',
    dataIndex: 'inPutTotal',
    key: 'inPutTotal',
    align: 'center',
  },
  {
    title: '销项税额合计(元)',
    dataIndex: 'outputTotal',
    key: 'outputTotal',
    align: 'center',
  },
];

export default Vue.extend({
  name: 'equipmentVat',
  mixins: [mixin],
  data() {
    return {
      columns,
    };
  },
  computed: {
    ...mapGetters(['currentTreeInfo']),
  },
  created() {
    this.getCjszzs();
  },
  methods: {
    getCjszzs() {
      (this as any).loading = true;
      getCjszzs(this.currentTreeInfo.bidSequenceNbr)
        .then((res) => {
          if (res.status === 200 && res.result) {
            (this as any).loading = false;
            (this as any).tableData = res.result;
          }
        })
        .finally(() => {
          (this as any).loading = false;
        });
    },
  },
});
