

































import Vue from 'vue';
import mixin from './mixin';

const columns = [
  {
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    title: '取费专业名称',
    dataIndex: 'professionName',
    key: 'professionName',
    align: 'center',
  },
  {
    title: '取费基数',
    dataIndex: 'custombase',
    key: 'custombase',
    align: 'center',
    scopedSlots: { customRender: 'custombase' },
  },
  {
    title: '取费金额',
    dataIndex: 'baseamount',
    key: 'baseamount',
    align: 'center',
  },
  {
    title: '基本费率(%)',
    dataIndex: 'safefeerate',
    key: 'safefeerate',
    align: 'center',
  },
  {
    title: '增加费率(%)',
    dataIndex: 'customsafefeerate',
    key: 'customsafefeerate',
    align: 'center',
    scopedSlots: { customRender: 'customsafefeerate' },
  },
  {
    title: '安全文明施工费金额',
    dataIndex: 'totalamount',
    key: 'totalamount',
    align: 'center',
  },
];

export default Vue.extend({
  name: 'safeProductionAndCivilizedConstruction',
  mixins: [mixin],
  data() {
    return {
      columns,
    };
  },
});
